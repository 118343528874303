import { resolveProgramSlug } from '@wix/ambassador-challenges-v1-challenge/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getChallengeSlugFromLocation,
  getLocationFromFlowAPI,
} from '../../Location/locationProviderPropsMap';
import requestChallenge from './api/requestChallenge';
import { getChallengeData } from './data';

let resolveSlugResponsePromise: Promise<{ data?: { programId?: string } }>;

const withCache = <T extends Function>(fn: T): T => {
  return ((...args: any[]) => {
    if (!resolveSlugResponsePromise) {
      resolveSlugResponsePromise = fn(...args);
    }
    return resolveSlugResponsePromise;
  }) as any as T;
};

export const resolveProgramId = withCache(
  async (
    flowAPI: ControllerFlowAPI,
  ): Promise<{
    programId: string;
    programData?: Awaited<ReturnType<typeof requestChallenge>>;
  }> => {
    const programId = getProgramIdFromQueryParams(flowAPI);
    if (programId) {
      return { programId };
    }
    if (!flowAPI.environment.isViewer) {
      return { programId: (await getChallengeData(flowAPI)).challenge.id };
    }
    const challengeSlugFromLocation = getChallengeSlugFromLocation(flowAPI);
    if (!challengeSlugFromLocation) {
      return { programId: '' };
    }
    const resolveSlugResponse = await flowAPI.httpClient.request(
      resolveProgramSlug({ slug: challengeSlugFromLocation }),
    );
    return { programId: resolveSlugResponse?.data?.programId };
  },
);

function getProgramIdFromQueryParams(flowAPI: ControllerFlowAPI) {
  return getLocationFromFlowAPI(flowAPI).query?.programId;
}

export const clearResolvedProgramIdCache = () => {
  resolveSlugResponsePromise = undefined;
};
